.container{
  position:relative;
  display:block;
  padding:1rem;
}

.container::before{
  animation: changeBg 40s infinite;
  background-size: contain;
  content:"";
  opacity:0.25;
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  left:0;
  width:auto;
  height:auto;
  z-index:-3;
}

@keyframes changeBg{
   0%,100%  {background-image: url("../img/rajiniji.jpg");}
   25% {background-image: url("../img/kamalji.jpg");}
   50% {background-image: url("../img/vijay.jpg");}
   75% {background-image: url("../img/ajith.jpg");}
}


/* Add a black background color to the top navigation */
.topnav {
    background-color: #636363;
    overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 7px 8px;
  text-decoration: none;
  font-size: 15px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #fc7b72;
  color: white;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}